import { useCallback } from 'react';
import { useRecoilState } from 'recoil';
import { useWallet } from '@solana/wallet-adapter-react';

import {
  isRefetchNecessaryState,
  ownedJuiceState,
  ownedNFTsState,
  ownedPenguinsState,
  penguinStakeDataState,
  penguinsNeedingStakeDataRefreshState,
  recentlyTransformedNootState,
} from '../../state/index';

export function useLogoutFn() {
  const { disconnect } = useWallet();
  const [, setOwnedNFTs] = useRecoilState(ownedNFTsState);
  const [, setPenguinStakeData] = useRecoilState(penguinStakeDataState);
  const [, setOwnedPenguins] = useRecoilState(ownedPenguinsState);
  const [, setJuices] = useRecoilState(ownedJuiceState);
  const [, setIsRefetchNecessary] = useRecoilState(isRefetchNecessaryState);
  const [, setRecentlyTransformedNoot] = useRecoilState(recentlyTransformedNootState);
  const [, setPenguinsNeedingStakeDataRefresh] = useRecoilState(
    penguinsNeedingStakeDataRefreshState
  );

  const logoutFn = useCallback(async () => {
    setOwnedNFTs(null);
    setOwnedPenguins(null);
    setPenguinsNeedingStakeDataRefresh(null);
    setPenguinStakeData(null);
    setJuices(null);
    setRecentlyTransformedNoot(null);
    setIsRefetchNecessary(false); // Will be set to true on login
    disconnect();
    // window.location.reload();
  }, [
    disconnect,
    setIsRefetchNecessary,
    setJuices,
    setOwnedNFTs,
    setOwnedPenguins,
    setPenguinStakeData,
    setPenguinsNeedingStakeDataRefresh,
    setRecentlyTransformedNoot,
  ]);

  return logoutFn;
}
