import { useCallback, useEffect, useState } from 'react';

import DarkIcon from './DarkIcon';
import LightIcon from './LightIcon';

// List of themes: https://daisyui.com/docs/themes/
// Configure avaialble themes in tailwind.config.js
const DARK_THEME = 'dark';
const LIGHT_THEME = 'light';

export default function DarkModeToggle() {
  const [mode, setMode] = useState(null);

  useEffect(() => {
    if (
      localStorage.getItem('color-theme') === DARK_THEME ||
      (!('color-theme' in localStorage) &&
        window.matchMedia('(prefers-color-scheme: dark)').matches)
    ) {
      setMode(DARK_THEME);
      document.documentElement.setAttribute('data-theme', DARK_THEME);
    } else {
      setMode(LIGHT_THEME);
      document.documentElement.setAttribute('data-theme', LIGHT_THEME);
    }
  }, [setMode]);

  const toggleMode = useCallback(() => {
    const newMode = mode === DARK_THEME ? LIGHT_THEME : DARK_THEME;
    localStorage.setItem('color-theme', newMode);
    setMode(newMode);
    if (newMode === DARK_THEME) {
      document.documentElement.setAttribute('data-theme', DARK_THEME);
    } else {
      document.documentElement.setAttribute('data-theme', LIGHT_THEME);
    }
  }, [mode, setMode]);

  return (
    <button
      id="theme-toggle"
      type="button"
      className="btn btn-primary rounded-lg text-sm p-2.5"
      onClick={toggleMode}
    >
      {mode === DARK_THEME ? <DarkIcon /> : <LightIcon />}
    </button>
  );
}
