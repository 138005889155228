import { useCallback } from 'react';
import { useRecoilState } from 'recoil';
import { isRefetchNecessaryState } from '../../state/index';

export function useLoginFn() {
  const [, setIsRefetchNecessary] = useRecoilState(isRefetchNecessaryState);

  const loginFn = useCallback(() => {
    setIsRefetchNecessary(true);
  }, [setIsRefetchNecessary]);

  return loginFn;
}
