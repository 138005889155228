import { useEffect, useMemo, useCallback } from 'react';
import LogRocket from 'logrocket';
import { useWallet } from '@solana/wallet-adapter-react';

import { useLoginFn } from '../lib/hooks/login';
import { useLogoutFn } from '../lib/hooks/logout';

export default function AuthProvider({ children }) {
  const { connected, disconnecting, publicKey } = useWallet();
  const login = useLoginFn();
  const logout = useLogoutFn();
  const userPubkey = useMemo(() => publicKey?.toBase58(), [publicKey]);

  const processLogin = useCallback(async () => {
    if (!userPubkey) {
      console.error('Login failed: user pubkey is not defined');
      return;
    }
    try {
      login();
      LogRocket.identify(userPubkey, { publicKey: userPubkey });
    } catch (err) {
      console.error('Failed to associate user ID with LogRocket', err);
    }
  }, [userPubkey, login]);

  useEffect(() => {
    if (disconnecting === true) {
      logout();
      return;
    }
  }, [disconnecting, logout]);

  useEffect(() => {
    if (!connected) {
      return; // Wallet is not connected, do nothing
    }
    processLogin();
  }, [connected, processLogin]);

  return children;
}
