import AuthProvider from '../components/AuthProvider';
import PlacesProvider from '../components/PlacesProvider';

export default function Layout({ children }) {
  return (
    <AuthProvider>
      <PlacesProvider>{children}</PlacesProvider>
    </AuthProvider>
  );
}
