import { atom, selector } from 'recoil';
import { isEmpty } from 'lodash-es';

/*********\
|  ATOMS  |
\*********/

// -- NFTs State -- \\
export const isFetchingNFTsState = atom({ key: 'isFetchingNFTsState', default: false });
export const isFetchingMetadataState = atom({ key: 'isFetchingMetadataState', default: false });
export const isRefetchNecessaryState = atom({ key: 'isRefetchNecessaryState', default: true });
export const ownedNFTsState = atom({ key: 'ownedNFTsState', default: null });

// -- Places State -- \\
export const availablePlacesState = atom({ key: 'availablePlaces', default: null });

// -- Penguins State -- \\
export const isLoadingPenguinsState = atom({ key: 'isLoadingPenguins', default: false });
export const ownedPenguinsState = atom({ key: 'ownedPenguins', default: null });
export const selectedPenguinState = atom({ key: 'selectedPenguinState', default: null });

// -- Juice State -- \\
export const allJuiceState = atom({ key: 'allJuiceState', default: null });
export const isLoadingJuiceState = atom({ key: 'isLoadingJuiceState', default: false });
export const ownedJuiceState = atom({ key: 'ownedJuices', default: null });
export const selectedJuiceState = atom({ key: 'selectedJuiceState', default: null });

// -- Staking State -- \\
export const isLoadingStakeDataState = atom({ key: 'isLoadingStakeData', default: true });
export const penguinStakeDataState = atom({ key: 'penguinStakeData', default: null });
export const penguinsNeedingStakeDataRefreshState = atom({
  key: 'penguinsNeedingStakeDataRefresh',
  default: [],
});

// -- Website State -- \\
export const recentlyTransformedNootState = atom({
  key: 'recentlyTransformedNootState',
  default: null,
});

// -- Loading State -- \\
export const numFetchedState = atom({ key: 'numFetchedState', default: null });
export const numNeedToFetchState = atom({ key: 'numNeedToFetchState', default: null });
export const numStakeDataFetchedState = atom({ key: 'numStakeDataFetchedState', default: null });

/*************\
|  SELECTORS  |
\*************/

export const numTotalStakeDataNeededState = selector({
  key: 'numTotalStakeDataNeededState',
  get: ({ get }) => {
    const penguins = get(penguinsNeedingStakeDataRefreshState);
    return penguins?.length || 0;
  },
});

export const totalNFTsState = selector({
  key: 'totalNFTsState',
  get: ({ get }) => {
    const nfts = get(ownedPenguinsState);
    if (isEmpty(nfts)) {
      return 0;
    }
    return nfts.length;
  },
});

export const scannedNFTsState = selector({
  key: 'scannedNFTsState',
  get: ({ get }) => {
    const stakeData = get(penguinStakeDataState);
    if (isEmpty(stakeData)) {
      return 0;
    }
    return stakeData.length;
  },
});

export const ownedPenguinIdsState = selector({
  key: 'ownedPenguinIds',
  get: ({ get }) => {
    const penguins = get(ownedPenguinsState);
    return penguins?.map((p) => p.tokenId);
  },
});

export const ogPenguinsState = selector({
  key: 'ogPenguinsState',
  get: ({ get }) => {
    const penguins = get(ownedPenguinsState);
    if (!penguins) {
      return null;
    }
    return penguins?.filter((p) => p.uri?.includes('-pre.json'));
  },
});

export const pixelPenguinsState = selector({
  key: 'pixelPenguinsState',
  get: ({ get }) => {
    const penguins = get(ownedPenguinsState);
    if (!penguins) {
      return null;
    }
    return penguins?.filter((p) => p.uri?.includes('-pixel.json'));
  },
});

export const hdPenguinsState = selector({
  key: 'hdPenguinsState',
  get: ({ get }) => {
    const penguins = get(ownedPenguinsState);
    if (!penguins) {
      return null;
    }
    return penguins?.filter((p) => p.image?.includes('-hd.png'));
  },
});

export const indexedStakeDataState = selector({
  key: 'indexedStakeDataState',
  get: ({ get }) => {
    const stakeData = get(penguinStakeDataState);
    if (!stakeData) {
      return null;
    }
    return (
      stakeData?.reduce((acc, data) => {
        acc[data.penguinId] = data;
        return acc;
      }, {}) || {}
    );
  },
});

export const penguinsByStakePlaceState = selector({
  key: 'penguinsByStakePlaceState',
  get: ({ get }) => {
    const stakeData = get(penguinStakeDataState);
    if (!stakeData) {
      return null;
    }
    return (
      stakeData?.reduce((acc, data) => {
        if (!acc[data.activity]) {
          acc[data.activity] = [];
        }
        acc[data.activity].push(data.penguinId);
        return acc;
      }, {}) || {}
    );
  },
});
