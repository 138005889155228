import { useCallback } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useWallet } from '@solana/wallet-adapter-react';

import {
  isLoadingStakeDataState,
  isRefetchNecessaryState,
  ownedJuiceState,
  ownedNFTsState,
  ownedPenguinsState,
  penguinStakeDataState,
  penguinsNeedingStakeDataRefreshState,
  recentlyTransformedNootState,
} from '../state';

export default function RefreshStateButton() {
  const [, setOwnedNFTs] = useRecoilState(ownedNFTsState);
  const [, setPenguinStakeData] = useRecoilState(penguinStakeDataState);
  const [, setOwnedPenguins] = useRecoilState(ownedPenguinsState);
  const [, setJuices] = useRecoilState(ownedJuiceState);
  const [, setIsRefetchNecessary] = useRecoilState(isRefetchNecessaryState);
  const [, setRecentlyTransformedNoot] = useRecoilState(recentlyTransformedNootState);
  const [, setPenguinsNeedingStakeDataRefresh] = useRecoilState(
    penguinsNeedingStakeDataRefreshState
  );
  const isLoadingStaking = useRecoilValue(isLoadingStakeDataState);
  const { publicKey } = useWallet();

  const refresh = useCallback(() => {
    setOwnedNFTs(null);
    setOwnedPenguins(null);
    setPenguinsNeedingStakeDataRefresh(null);
    setPenguinStakeData(null);
    setJuices(null);
    setRecentlyTransformedNoot(null);
    setIsRefetchNecessary(true);
  }, [
    setIsRefetchNecessary,
    setJuices,
    setOwnedNFTs,
    setOwnedPenguins,
    setPenguinStakeData,
    setPenguinsNeedingStakeDataRefresh,
    setRecentlyTransformedNoot,
  ]);

  if (!publicKey) {
    return null;
  }
  if (isLoadingStaking) {
    return <button className="btn loading ghost">Loading Stake Data</button>;
  }
  return (
    <>
      <button className="md:hidden btn btn-accent" onClick={refresh}>
        Refresh
      </button>
      <button className="hidden md:flex btn btn-accent" onClick={refresh}>
        Refresh Stake Data
      </button>
    </>
  );
}
