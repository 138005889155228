import Link from 'next/link';
import { useRouter } from 'next/router';
import { useCallback, useMemo } from 'react';
import { MdClose } from 'react-icons/md';

import { useRecoilState, useRecoilValue } from 'recoil';
import { availablePlacesState, penguinsByStakePlaceState } from '../state';
import { mapPanTargetState } from '../state/map';
import POIs from '../data/poi.json';

import RefreshStateButton from './RefreshStateButton';
import ThemeToggleButton from './ThemeToggleButton';

const SELECTED_CLASSES = 'bg-secondary-focus text-secondary-content';

export default function AppDrawer({ toggleCallback = () => null }) {
  const router = useRouter();
  const { asPath } = router;
  const availablePlaces = useRecoilValue(availablePlacesState);
  const penguinPlacesLookup = useRecoilValue(penguinsByStakePlaceState);
  const [, setMapPanTarget] = useRecoilState(mapPanTargetState);

  const currentPage = useMemo(() => {
    const pages = asPath.split('/');
    return pages[pages.length - 1];
  }, [asPath]);

  const panToCoords = useCallback(setMapPanTarget, [setMapPanTarget]);

  /* TODO: Split POIs into Categories when we have tons of them
  const categories = useMemo(
    () =>
      Object.entries(
        POIs.reduce((acc, poi) => {
          const { category } = poi;
          if (!acc[category]) {
            acc[category] = [];
          }
          acc[category].push(poi);
          return acc;
        }, {})
      ),
    []
  );
  */

  return (
    <div className="drawer-side h-full max-h-full overflow-hidden">
      <label htmlFor="app-drawer" className="drawer-overlay"></label>
      <div className="flex flex-col bg-neutral/90 w-[320px] max-h-[100vh] text-neutral-content">
        <div className="flex items-center justify-between cursor-pointer p-2">
          <ThemeToggleButton />
          <div className="btn p-0" onClick={() => toggleCallback()}>
            <MdClose size="36px" color="white" />
          </div>
        </div>
        <div className="divider my-1 before:bg-neutral-content after:bg-neutral-content" />
        <div className="flex flex-col h-full">
          {/* Points of Interest */}
          {POIs?.map(({ name, path, coords }) => (
            <Link
              key={`poi-menu-${name}-${path}`}
              href={path}
              passHref={true}
              className={`collapse-title font-medium font-thinSugar text-2xl hover:bg-primary hover:bg-text-primary-content ${
                currentPage?.length > 1 && path.includes(currentPage) ? SELECTED_CLASSES : ''
              }`}
              onClick={() => {
                panToCoords(coords);
                // toggleCallback(false);
              }}
              {...(path[0] !== '/' ? { target: '_blank', rel: 'noreferrer' } : {})}
            >
              {name}
            </Link>
          ))}

          {/*
            // TODO: When POIs split into categories, use this
            categories?.map(([category, pois]) => (
            <div key={`poi-category-${category}`} className="collapse collapse-plus">
              <input type="checkbox" />
              <div className="collapse-title font-medium font-thinSugar text-2xl text-neutral-content">
                {category}
              </div>
              <div className="collapse-content">
                <ul className="menu overflow-y-auto text-neutral-content">
                  {pois.map(({ name, path, coords }) => (
                    <li
                      key={`poi-${category}-${name}`}
                      className={`rounded hover:bg-primary hover:text-primary-content ${
                        currentPage.length && path.includes(currentPage) ? SELECTED_CLASSES : ''
                      }`}
                      onClick={() => panToCoords(coords)}
                    >
                      <Link href={path} passHref={true}>
                        <a>{name}</a>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )) */}
          <div className="flex-grow" />
          <div className="divider my-3 before:bg-neutral-content after:bg-neutral-content" />
          <div className="flex w-full px-4 pb-2 items-center justify-center">
            <RefreshStateButton />
          </div>
        </div>
      </div>
    </div>
  );
}
