import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useMemo, useState } from 'react';

import AppDrawer from './AppDrawer';

export default function Layout({ children }) {
  const [isOpen, setIsOpen] = useState(false);
  const Map = useMemo(() => dynamic(() => import('./Map'), { ssr: false }), []);
  const TopBar = useMemo(() => dynamic(() => import('./TopBar'), { ssr: false }), []);
  const router = useRouter();
  const { asPath } = router;

  return (
    <div className="drawer">
      <input
        className="drawer-toggle"
        id="app-drawer"
        onChange={() => setIsOpen((oldVal) => !oldVal)}
        type="checkbox"
        checked={isOpen}
      />
      <div className="flex flex-col h-full w-full max-h-full drawer-content items-center">
        <TopBar className="absolute" />
        <Map className="absolute flex h-full flex-grow w-full" />
        {asPath.length > 1 && (
          <div
            className="absolute z-[1200] w-full md:w-11/12 max-w-screen-xl top-16 overflow-y-auto md:rounded-lg transform-gpu"
            style={{
              maxHeight: 'calc(100% - 72px)',
            }}
          >
            {children}
          </div>
        )}
      </div>
      <AppDrawer toggleCallback={() => setIsOpen((oldval) => !oldval)} />
    </div>
  );
}
