import axios from 'axios';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { toast } from 'react-toastify';

import { useLogoutFn } from '../lib/hooks/logout';
import { availablePlacesState } from '../state';

export default function PlacesProvider({ children }) {
  const [, setAvailablePlaces] = useRecoilState(availablePlacesState);
  const logout = useLogoutFn();

  useEffect(() => {
    axios
      .get('/api/places')
      .then((r) => {
        if (r?.data) {
          const places = r.data;
          setAvailablePlaces(places);
        }
      })
      .catch((err) => {
        if (err.message.includes('403')) {
          console.warn('Your session has expried, redirecting you to the homepage');
          logout();
          return;
        }
        toast.error('Failed to load data; hit refresh!');
      });
  }, [logout, setAvailablePlaces]);

  return children;
}
