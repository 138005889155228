import { useEffect } from 'react';
import { RecoilRoot } from 'recoil';
import { useRouter } from 'next/router';
import Head from 'next/head';
import * as Fathom from 'fathom-client';
// import LogRocket from 'logrocket';
// import setupLogRocketReact from 'logrocket-react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// import { LOGROCKET_ID } from '../lib/config';
import Layout from '../components/Layout';
import AppStateProvider from '../components/AppStateProvider';
import '../styles/globals.css';

export { reportWebVitals } from 'next-axiom';

export default function NootopiaGame({ Component, pageProps }) {
  const router = useRouter();
  useEffect(() => {
    /*
    LogRocket.init(LOGROCKET_ID);
    setupLogRocketReact(LogRocket);
    */
    Fathom.load('ZLMSMQOK', {
      includedDomains: ['legacy.nootopia.app'],
    });
    function onRouteChangeComplete() {
      Fathom.trackPageview();
    }
    // Record a pageview when route changes
    router.events.on('routeChangeComplete', onRouteChangeComplete);
    // Unassign event listener
    return () => {
      router.events.off('routeChangeComplete', onRouteChangeComplete);
    };
  }, [router.events]);

  return (
    <>
      <Head>
        <title>Nootopia</title>
      </Head>
      <ToastContainer position="top-center" />
      <RecoilRoot>
        <AppStateProvider>
          <Layout>
            <Component {...pageProps} />
          </Layout>
        </AppStateProvider>
      </RecoilRoot>
    </>
  );
}
